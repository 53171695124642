import React from 'react';
import {
  Card,
  CardSection,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Layout,
  Paragraph,
  SmallHeader,
} from '@axiom/ui';

import { PageLayout } from '../layouts/PageLayout';
import Rocket from '../public/svg/rocket.svg';

export const HelpCenterChangesPage = () => (
  <PageLayout>
    <Grid>
      <GridRow>
        <GridColumn width={2} />
        <GridColumn width={8}>
          <Card name="HELP-CENTER-PAGE">
            <CardSection>
              <Layout direction="horizontal" horizontalGutter="24px">
                <div>
                  <Rocket width="117px" height="117px" />
                </div>
                <div>
                  {' '}
                  <SmallHeader>New Knowledge Center – Coming Soon!</SmallHeader>
                  <Gutter bottom="16px" />
                  <Paragraph>
                    The Help Center is being replaced with a simplified
                    “documents on-demand” experience to connect you with the
                    information you need to build and manage your Axiom career.
                    We appreciate your patience as we work on rolling out these
                    exciting updates. If you have any questions in the meantime,
                    please don't hesitate to reach out to us.
                  </Paragraph>
                  <Gutter bottom="16px" />
                  <Paragraph>
                    <ul>
                      <li>
                        General Inquiries:{' '}
                        <a href="mailto:answers@axiomlaw.com">
                          answers@axiomlaw.com
                        </a>
                      </li>
                      <li>
                        IT Support:{' '}
                        <a href="mailto:support@axiomlaw.com">
                          support@axiomlaw.com
                        </a>
                      </li>
                    </ul>
                  </Paragraph>
                </div>
              </Layout>
            </CardSection>
          </Card>
        </GridColumn>
      </GridRow>
    </Grid>
  </PageLayout>
);
