import moment from 'moment';
import {
  ProfileCompletionConst,
  CandidateProfileIssueConst,
  CandidateOpportunitiesConst,
  PositionsConst,
  CandidatesConst,
  ProfileCompletionCriteriaDetail,
} from '@axiom/const';
import {
  Candidate,
  CandidateOpportunity,
  CandidateProfileIssue,
} from '@axiom/validation';

export const CandidateProfileIssuesUtils = {
  hasPracticeStartYearIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory
              .PracticeStartYear &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing
      ) ?? false
    );
  },

  hasValidNameIssues(issues: CandidateProfileIssue[] = []) {
    return this.getNameErrors(issues).length > 0;
  },

  getNameErrors(issues: CandidateProfileIssue[] = []) {
    const errors = [];

    // Check if there are too many capital letters in the name
    if (
      issues?.some(
        issue =>
          issue.name ===
          CandidateProfileIssueConst.CandidateProfileIssueName.AllCaps
      ) ??
      false
    ) {
      errors.push(ProfileCompletionConst.ProfileCompletionNameBanner.allCaps);
    }

    // Check for specified qualifiers, 3 or more consecutive capital letters are included
    if (
      issues?.some(
        issue =>
          issue.name ===
          CandidateProfileIssueConst.CandidateProfileIssueName.Qualifications
      ) ??
      false
    ) {
      errors.push(
        ProfileCompletionConst.ProfileCompletionNameBanner.qualifications
      );
    }

    // Check if there are any titles in the name (title check is in beginning)
    if (
      issues?.some(
        issue =>
          issue.name ===
          CandidateProfileIssueConst.CandidateProfileIssueName.Titles
      ) ??
      false
    ) {
      errors.push(ProfileCompletionConst.ProfileCompletionNameBanner.titles);
    }

    return errors;
  },

  hasSummaryOfAnyLengthIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Summary &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing
      ) ?? false
    );
  },

  hasSummaryOfSufficientLengthIssues(issues: CandidateProfileIssue[] = []) {
    return (
      (issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Summary &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.LengthShort
      ) &&
        !this.hasSummaryOfAnyLengthIssues(issues)) ??
      false
    );
  },

  hasNonAxiomExperienceIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Experience &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing &&
          issue.name ===
            CandidateProfileIssueConst.CandidateProfileIssueExperience
              .NonAxiomMissing
      ) ?? false
    );
  },

  hasExperienceWithMissingIndustryIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Experience &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing &&
          issue.name ===
            CandidateProfileIssueConst.CandidateProfileIssueExperience
              .MissingIndustry
      ) ?? false
    );
  },

  hasExperienceWithMissingIndustryIssue(
    issues: CandidateProfileIssue[] = [],
    experienceId: string
  ) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Experience &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing &&
          issue.name ===
            CandidateProfileIssueConst.CandidateProfileIssueExperience
              .MissingIndustry &&
          issue.entityId === (experienceId ?? '')
      ) ?? false
    );
  },

  hasAxiomExperienceWithBlankDescriptionIssues(
    issues: CandidateProfileIssue[] = []
  ) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Experience &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Content &&
          issue.name ===
            CandidateProfileIssueConst.CandidateProfileIssueExperience
              .AxiomBlankDescription
      ) ?? false
    );
  },

  hasLegalTechSkillsIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory
              .LegalTechSkills &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing
      ) ?? false
    );
  },

  hasDegreesIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Education &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing
      ) ?? false
    );
  },

  hasCertificationsIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory
              .Accomplishments &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing
      ) ?? false
    );
  },

  hasNativeLanguageIssues(issues: CandidateProfileIssue[] = []) {
    return (
      issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Languages &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Missing
      ) ?? false
    );
  },

  getNeededCompletionSteps(issues: CandidateProfileIssue[] = []) {
    const { ProfileCompletionCriteria, ProfileCompletionCriteriaDetails } =
      ProfileCompletionConst;

    return [
      this.hasPracticeStartYearIssues(issues) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsPracticeStartYear
        ],
      this.hasValidNameIssues(issues) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsValidName
        ],
      (this.hasSummaryOfAnyLengthIssues(issues) ||
        this.hasSummaryOfSufficientLengthIssues(issues)) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsAcceptableSummary
        ],
      this.hasNonAxiomExperienceIssues(issues) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsNonAxiomExperience
        ],
      this.hasExperienceWithMissingIndustryIssues(issues) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsNoExperienceWithMissingIndustry
        ],
      this.hasAxiomExperienceWithBlankDescriptionIssues(issues) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsNoAxiomExperienceWithBlankDescription
        ],
      this.hasLegalTechSkillsIssues(issues) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsLegalTechSkills
        ],
      this.hasDegreesIssues(issues) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsDegrees
        ],
      this.hasNativeLanguageIssues(issues) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsNativeLanguage
        ],
      this.hasCertificationsIssues(issues) &&
        ProfileCompletionCriteriaDetails[
          ProfileCompletionCriteria.needsCertifications
        ],
    ].filter(Boolean);
  },

  shouldShowProfileCompletionSection(
    candidate: Candidate,
    candidateOpportunities: CandidateOpportunity[],
    issues: CandidateProfileIssue[]
  ) {
    const excludedByProfileStatus = [
      CandidatesConst.ProfileStatuses.NewLead,
      CandidatesConst.ProfileStatuses.Certifying,
      CandidatesConst.ProfileStatuses.Reservoir,
    ].includes(candidate.profileStatus);
    const hasRollOffWithinSixWeeks = candidateOpportunities.some(
      ({ candidateStatus, position }) =>
        candidateStatus ===
          CandidateOpportunitiesConst.CandidateStatuses.Engaged &&
        position.endDateStatus === PositionsConst.EndDateStatuses.Confirmed &&
        moment(position.endDate)
          .startOf('day')
          .isSameOrBefore(moment().add(6, 'weeks').startOf('day'))
    );
    const neededCompletionSteps: ProfileCompletionCriteriaDetail[] =
      CandidateProfileIssuesUtils.getNeededCompletionSteps(issues);
    return !!(
      !excludedByProfileStatus &&
      neededCompletionSteps.length > 0 &&
      (candidate.weeklyAvailability ||
        hasRollOffWithinSixWeeks ||
        candidate.isAccessibleToWorkFeed)
    );
  },
};
