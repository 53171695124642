import {
  CURRENT_USER_NAMESPACE,
  CurrentUserStore,
} from './protected/current-user-store';
import {
  BANNER_MESSAGES_NAMESPACE,
  BannerMessagesStore,
} from './protected/banner-messages-store';
import {
  BARRED_LOCATION_NAMESPACE,
  BarredLocationsStore,
} from './protected/barred-locations-store';
import {
  CURRENT_CANDIDATE_NAMESPACE,
  CurrentCandidateStore,
} from './protected/current-candidate-store';
import {
  FORM_PROFILE_NAMESPACE,
  FormProfileStore,
} from './protected/form-profile-store';
import { APP_NAMESPACE, AppStore } from './app-store';
import {
  NOTIFICATIONS_NAMESPACE,
  NotificationsStore,
} from './protected/notifications-store';
import { SKILLS_NAMESPACE, SkillsStore } from './protected/skills-store';
import {
  FORM_NEW_OPPORTUNITY_YES_NAMESPACE,
  FormNewOpportunityYesStore,
} from './protected/form-new-opportunity-yes-store';
import {
  FORM_NEW_OPPORTUNITY_NO_NAMESPACE,
  FormNewOpportunityNoStore,
} from './protected/form-new-opportunity-no-store';
import {
  FORM_NEW_OPPORTUNITY_UNSURE_NAMESPACE,
  FormNewOpportunityUnsureStore,
} from './protected/form-new-opportunity-unsure-store';
import { FormEducationItemStore } from './protected/form-education-item-store';
import {
  CANDIDATE_EXPERIENCE_NAMESPACE,
  CandidateExperiencesStore,
} from './protected/candidate-experiences-store';
import {
  CANDIDATE_LANGUAGE_NAMESPACE,
  CandidateLanguagesStore,
} from './protected/candidate-languages-store';
import { CandidateOpportunitiesStore } from './protected/candidate-opportunities-store';
import { FormAvailabilityPreferencesStore } from './protected/form-availability-preferences-store';
import { LawSchoolsStore } from './protected/law-schools-store';
import { PreloadedFeatureFlagsStore } from './preloaded-feature-flags-store';
import { DigitalSubmissionStore } from './digital-submission-store';
import { ConfirmationsStore } from './confirmations-store';
import { DeleteProfileImageModalStore } from './protected/delete-profile-image-modal-store';
import { PhotoConsentModalStore } from './protected/photo-consent-modal-store';
import { UserStore } from './protected/user-store';
import { PreloadedPracticeAreasStore } from './preloaded-practice-areas-store';
import { CandidateStore } from './protected/candidate-store';
import { PreloadedPermissionsStore } from './preloaded-permissions-store';
import { PreloadedBarredLocationsStore } from './preloaded-barred-locations-store';

export const stores = {
  BARRED_LOCATIONS_PRELOADED: PreloadedBarredLocationsStore,
  [APP_NAMESPACE]: AppStore,
  [BANNER_MESSAGES_NAMESPACE]: BannerMessagesStore,
  [BARRED_LOCATION_NAMESPACE]: BarredLocationsStore,
  [CANDIDATE_EXPERIENCE_NAMESPACE]: CandidateExperiencesStore,
  [CANDIDATE_LANGUAGE_NAMESPACE]: CandidateLanguagesStore,
  CANDIDATE_OPPORTUNITIES: CandidateOpportunitiesStore,
  CONFIRMATIONS_STORE: ConfirmationsStore,
  [CURRENT_CANDIDATE_NAMESPACE]: CurrentCandidateStore,
  [CURRENT_USER_NAMESPACE]: CurrentUserStore,
  DELETE_PROFILE_IMAGE_MODAL: DeleteProfileImageModalStore,
  DIGITAL_SUBMISSION: DigitalSubmissionStore,
  LAW_SCHOOLS: LawSchoolsStore,
  PHOTO_CONSENT_MODAL: PhotoConsentModalStore,
  FEATURE_FLAGS: PreloadedFeatureFlagsStore,
  FORM_AVAILABILITY_PREFERENCES_STORE: FormAvailabilityPreferencesStore,
  FORM_EDUCATION_ITEM_STORE: FormEducationItemStore,
  [FORM_NEW_OPPORTUNITY_NO_NAMESPACE]: FormNewOpportunityNoStore,
  [FORM_NEW_OPPORTUNITY_UNSURE_NAMESPACE]: FormNewOpportunityUnsureStore,
  [FORM_NEW_OPPORTUNITY_YES_NAMESPACE]: FormNewOpportunityYesStore,
  [FORM_PROFILE_NAMESPACE]: FormProfileStore,
  [NOTIFICATIONS_NAMESPACE]: NotificationsStore,
  PERMISSIONS: PreloadedPermissionsStore,
  PRACTICE_AREAS: PreloadedPracticeAreasStore,
  [SKILLS_NAMESPACE]: SkillsStore,
  USER: UserStore,
  CANDIDATE_STORE: CandidateStore,
};

export const initialState = {
  [CURRENT_CANDIDATE_NAMESPACE]: CurrentCandidateStore.getInitialState(),
};

// This whole if statement should automatically be removed during a production build, since
// it only applies to development mode.
if (module.hot) {
  // This solves the problem of GenericStores losing their identities and initialization
  // during hot module reload in development. The problem is that, when the modules are
  // reloaded, the store objects (like FormLoginStore) are re-created. However, the redux store
  // isn't reinitialized from scratch, i.e. store-manager's initializeStore() is not called.
  // This is correct behavior, we don't want to reinitialize the entire store, but it means
  // that the new GenericStore objects never get initializeNamespace and initializeDispatch
  // called on them, so their internal states aren't correct. To solve this, we need intercept
  // the reload and save the old internal states of those objects, then restore them when
  // we are reinitialized.

  // This logic is put in this file because, since this file imports all the GenericStores
  // in the project, it will itself be reloaded any time one of the stores is reloaded. The
  // alternative would be to duplicate (a slightly modified version of) this logic into
  // every file that creates a GenericStore.

  // dispose is called when we are about to be unloaded as part of a hot reload. We can
  // write values into the data object and the reload runtime will persist them into the
  // new version of this module that is being loaded.
  module.hot.dispose(data => {
    Object.keys(stores).forEach(namespace => {
      data[namespace] = { ...stores[namespace] };
    });
  });

  // data that was persisted from the previous version of our module shows up in module.hot.data,
  // so if this has content then it means we are coming after some older version of this module
  // that we replaced during a hot reload. We should attempt to restore any information present
  // in the data that was provided to us, as long as the store doesn't already have its own
  // version.
  if (module.hot.data) {
    Object.keys(module.hot.data).forEach(namespace => {
      const store = stores[namespace];
      const data = module.hot.data[namespace];
      Object.keys(data).forEach(key => {
        store[key] = store[key] || data[key];
      });
    });
  }
}
