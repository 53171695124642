import React, { useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardSection,
  Gutter,
  Layout,
  LayoutItem,
  SmallHeader,
  Suspense,
  useApi,
} from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import { CandidateLanguageUtil } from '../../utils/candidate-language-util';
import { CandidateLanguagesApi } from '../../api/protected/candidates/candidate-languages';

import { LanguagesGroup } from './LanguagesGroup';
import { LanguagesBannerPrompt } from './LanguagesBannerPrompt';
import { LanguagesItemEdit } from './LanguagesItemEdit';

export const Languages = ({
  candidateId,
}: {
  candidateId: Candidate['id'];
}) => {
  const [languageEdit, setLanguageEdit] = useState(false);
  const [{ data: candidateLanguages }] = useApi(
    CandidateLanguagesApi.readLanguages(candidateId)
  );

  const handleEditClick = () => {
    setLanguageEdit(true);
  };

  const handleModalClose = () => {
    setLanguageEdit(false);
  };

  return (
    <Suspense>
      <Card name="LANGUAGES">
        <CardHeader>
          <Layout position="middle">
            <LayoutItem fluid>
              <SmallHeader name="PENDO-PROFILE-SUMMARY">Languages</SmallHeader>
            </LayoutItem>
            <Button
              name="LANGUAGE_ADD_BUTTON"
              pattern="secondary"
              variation="minimal"
              icon="plus"
              onClick={() => handleEditClick()}
            >
              Add
            </Button>
          </Layout>
        </CardHeader>
        {candidateLanguages.length === 0 && (
          <CardSection>
            <LanguagesBannerPrompt onOpenEdit={handleEditClick} />
            <Gutter bottom="16px" />
          </CardSection>
        )}
        {candidateLanguages.length > 0 &&
          Object.entries(
            CandidateLanguageUtil.groupByCategories(candidateLanguages)
          ).map(([languageName, groupings]) => {
            return (
              <LanguagesGroup
                key={languageName}
                candidateId={candidateId}
                candidateLanguages={candidateLanguages}
                languageName={languageName}
                languageGroups={groupings}
              />
            );
          })}
        {languageEdit && (
          <LanguagesItemEdit
            candidateId={candidateId}
            candidateLanguages={candidateLanguages}
            onClose={() => handleModalClose()}
          />
        )}
      </Card>
    </Suspense>
  );
};
