import React from 'react';
import { Flashy, ParagraphHeader, Paragraph } from '@axiom/ui';
import { CandidateOpportunitiesConst, ClientRequestConst } from '@axiom/const';

const { CandidateRejectionLossCodes } = CandidateOpportunitiesConst;
const { AfcRejectionLossCodes } = ClientRequestConst;

export const RejectionLossCodesUtil = {
  defaultMessages: {
    [CandidateRejectionLossCodes.CannotWorkOnAdhocEngagements]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.CannotWorkOnAdhocEngagements}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because the hours required for this engagement do
        not meet the minimums required for your state.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.ClientDriven]: (
      <Paragraph name={`DEFAULT_${CandidateRejectionLossCodes.ClientDriven}`}>
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The opportunity is no longer available
          </ParagraphHeader>
        </Flashy>{' '}
        due to a change in client needs. This usually happens when a client
        decides to go in a different direction with the planned work.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.ConflictOfInterest]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.ConflictOfInterest}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity due to a conflict of interest that might prevent
        you from performing the work.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.CostTooExpensive]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.CostTooExpensive}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity due to client budget constraints.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.ExperienceLackingIndustrySpecificExperience]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.ExperienceLackingIndustrySpecificExperience}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity as your background didn't have the relevant
        industry experience.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.ExperienceOverqualified]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.ExperienceOverqualified}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity as it didn't match the required level of experience
        as determined by the client.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.ExperiencePoorPracticeArea]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.ExperiencePoorPracticeArea}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your background didn't have the relevant
        practice area.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.ExperienceUnderqualified]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.ExperienceUnderqualified}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity as the client was looking for more experience.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.ExpressedInterestTooLateIntoProcess]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.ExpressedInterestTooLateIntoProcess}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">You weren't selected</ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because it closed before your profile could be
        shared with the client.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.IncorrectLanguage]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.IncorrectLanguage}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because you don't have the required language skill
        set.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.InsufficientAvailability]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.InsufficientAvailability}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your availability doesn't match up with the
        client's requirements.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.ProfileNeedsMoreDetailForSubmission]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.ProfileNeedsMoreDetailForSubmission}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">You weren't selected</ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your Axiom for Talent profile is
        incomplete.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.QualifiedCandidateThatWasNotSelected]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.QualifiedCandidateThatWasNotSelected}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">You weren't selected</ParagraphHeader>
        </Flashy>{' '}
        for this opportunity since the client has selected another legal
        professional for this opportunity.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.TimeZoneLocation]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.TimeZoneLocation}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your location or time zone doesn't match up
        with what the client is looking for.
      </Paragraph>
    ),
    [CandidateRejectionLossCodes.WrongTalentType]: (
      <Paragraph
        name={`DEFAULT_${CandidateRejectionLossCodes.WrongTalentType}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            Your profile was not submitted
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your legal role is different than what the
        client requires.
      </Paragraph>
    ),
  },
  rejectedByClientMessages: {
    [AfcRejectionLossCodes.ConflictOfInterest]: (
      <Paragraph name={`CLIENT_${AfcRejectionLossCodes.ConflictOfInterest}`}>
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity due to a possible conflict of interest with
        something in your background or experience.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.CostTooExpensive]: (
      <Paragraph name={`CLIENT_${AfcRejectionLossCodes.CostTooExpensive}`}>
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity due to budget constraints.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.ExperienceLackingIndustrySpecificExperience]: (
      <Paragraph
        name={`CLIENT_${AfcRejectionLossCodes.ExperienceLackingIndustrySpecificExperience}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because they need someone whose background includes
        more relevant industry experience.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.ExperienceOverqualified]: (
      <Paragraph
        name={`CLIENT_${AfcRejectionLossCodes.ExperienceOverqualified}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your profile doesn't match up well with the
        required level of experience.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.ExperienceUnderqualified]: (
      <Paragraph
        name={`CLIENT_${AfcRejectionLossCodes.ExperienceUnderqualified}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because they are looking for a little more
        experience.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.IncorrectLanguage]: (
      <Paragraph name={`CLIENT_${AfcRejectionLossCodes.IncorrectLanguage}`}>
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your profile doesn't indicate that you have
        the required language skills.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.Other]: (
      <Paragraph name={`CLIENT_${AfcRejectionLossCodes.Other}`}>
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity but did not provide a reason.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.ProfileNeedsMoreDetail]: (
      <Paragraph
        name={`CLIENT_${AfcRejectionLossCodes.ProfileNeedsMoreDetail}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your Axiom profile is incomplete.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.QualifiedCandidateThatWasNotSelected]: (
      <Paragraph
        name={`CLIENT_${AfcRejectionLossCodes.QualifiedCandidateThatWasNotSelected}`}
      >
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client was impressed with your qualifications
          </ParagraphHeader>
        </Flashy>{' '}
        but decided to move forward with another Axiom legal talent for this
        opportunity.
      </Paragraph>
    ),
    [AfcRejectionLossCodes.TimeZoneLocation]: (
      <Paragraph name={`CLIENT_${AfcRejectionLossCodes.TimeZoneLocation}`}>
        <Flashy color="textSecondary">
          <ParagraphHeader as="span">
            The client decided not to interview you
          </ParagraphHeader>
        </Flashy>{' '}
        for this opportunity because your location or time zone doesn’t match up
        with the client's business needs.
      </Paragraph>
    ),
  },
};
