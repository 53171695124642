import { Api } from '@axiom/ui';
import {
  Candidate,
  CandidateLanguage,
  CandidateLanguageUpdate,
} from '@axiom/validation';

import { EnvUtil } from '../../../utils/env-util';

class CandidateLanguagesApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readLanguages(candidateId: Candidate['id']) {
    return super.read<{
      data: Array<CandidateLanguage>;
    }>({
      endpoint: `/candidates/${candidateId}/languages`,
      method: 'GET',
    });
  }

  updateLanguages(
    candidateId: Candidate['id'],
    body: CandidateLanguageUpdate[]
  ) {
    return super.write<boolean>({
      endpoint: `/candidates/${candidateId}/languages`,
      body,
      method: 'PUT',
    });
  }

  refreshLanguages(candidateId: Candidate['id']) {
    return super.invalidate(`/candidates/${candidateId}/languages`);
  }
}

export const CandidateLanguagesApi = new CandidateLanguagesApiClass();
