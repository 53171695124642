import React from 'react';
import { z } from 'zod';
import { Candidate, User } from '@axiom/validation';
import { Form, Layout, Toggle } from '@axiom/ui';

import { isLPUser } from '../../utils/roles';
import { CandidateApi } from '../../api/protected/candidates/candidate';
import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';

const formSchema = z.object({
  isSummaryApproved: z.boolean(),
});

export const ProfileSummaryConfirm = ({
  candidateId,
  isSummaryApproved,
  user,
}: {
  candidateId: Candidate['id'];
  isSummaryApproved: boolean;
  user: User;
}) => {
  return (
    <div data-test="PROFILE_SUMMARY_CONFIRM">
      {isLPUser(user) ? (
        <Layout position="middle" horizontalGutter="8px" name="SUMMARY_STATUS">
          {isSummaryApproved ? (
            <span data-test="PENDO-PROFILE-IMAGE-REVIEWED">
              Axiom Review Complete
            </span>
          ) : (
            <span data-test="PENDO-PROFILE-IMAGE-PENDING">
              Axiom Review Pending
            </span>
          )}
        </Layout>
      ) : (
        <Form
          schema={formSchema}
          initialValues={{
            isSummaryApproved,
          }}
          submitOnChange
          onSubmit={async formData => {
            await CandidateApi.updateCandidate(candidateId, {
              candidateSummaryApprovedBy: formData.isSummaryApproved
                ? user.id
                : null,
            });
            await CandidateApi.refreshCandidate(candidateId);
            await CandidateProfileIssuesApi.refreshCandidateProfileIssues(
              candidateId
            );
          }}
          name="ProfileSummaryConfirmForm"
        >
          {() => {
            return (
              <Toggle
                name="isSummaryApproved"
                description="Profile content review complete?"
              />
            );
          }}
        </Form>
      )}
    </div>
  );
};
