import React from 'react';
import { Link } from 'react-router-dom';
import { ProfileCompletionCriteriaDetail } from '@axiom/const';
import { CandidateProfileIssuesUtils } from '@axiom/utils';
import { Candidate } from '@axiom/validation';
import {
  Badge,
  Card,
  CardSection,
  Gutter,
  Paragraph,
  Layout,
  Flashy,
  LayoutItem,
  Icon,
  ParagraphHeader,
  Well,
  useApi,
} from '@axiom/ui';

import { ProfileCompletionUtil } from '../../utils/profile-completion-util';
import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';

export const ProfileCompletionChecklist = ({
  candidateId,
}: {
  candidateId: Candidate['id'];
}) => {
  const [{ data: issues }] = useApi(
    CandidateProfileIssuesApi.readCandidateProfileIssues(candidateId)
  );

  const neededCompletionSteps: ProfileCompletionCriteriaDetail[] =
    CandidateProfileIssuesUtils.getNeededCompletionSteps(issues);

  return neededCompletionSteps.length > 0 ? (
    <Card>
      <CardSection background="blue">
        <Badge background="red">Action Needed</Badge>
        <Gutter bottom="16px" />
        <Paragraph>
          Your profile is how clients decide whether they want to engage you,
          and it’s how Axiom finds you to recommend you to clients. Here’s what
          you need to do to get your profile completed, and get the kind of work
          you want faster.
        </Paragraph>
        <Gutter bottom="24px" />
        <Well background="light">
          <Layout
            name="required-completion-steps"
            direction="vertical"
            verticalGutter="16px"
          >
            {neededCompletionSteps.map(
              ({ name, remediation, additionalDetail }) => {
                const appendDetail =
                  ProfileCompletionUtil.getActionsNeededCTA[name];

                return (
                  <LayoutItem key={name}>
                    <Layout
                      direction="horizontal"
                      horizontalGutter="8px"
                      name={`pendo-${name}`}
                    >
                      <Flashy color="iconAlert">
                        <Icon name="alert-filled" />
                      </Flashy>
                      <Paragraph>
                        <Flashy color="textDefault">
                          <ParagraphHeader as="span">
                            {remediation}
                          </ParagraphHeader>{' '}
                          {additionalDetail}{' '}
                          {appendDetail?.ctaTitle && appendDetail?.ctaLink && (
                            <Link
                              to={appendDetail.ctaLink}
                              data-test={appendDetail.ctaTitle}
                            >
                              {appendDetail.ctaTitle}
                            </Link>
                          )}
                        </Flashy>
                      </Paragraph>
                    </Layout>
                  </LayoutItem>
                );
              }
            )}
          </Layout>
        </Well>
      </CardSection>
    </Card>
  ) : null;
};
