import React from 'react';
import {
  useApi,
  Grid,
  GridColumn,
  Card,
  Dropdown,
  CardSection,
  CardHeader,
  SmallHeader,
  Form,
  Gutter,
} from '@axiom/ui';
import { CandidateSchema } from '@axiom/validation';
import { z } from 'zod';
import { useParams } from 'react-router-dom';

import { PreferencesNavigation } from '../PreferencesNavigation/PreferencesNavigation';
import { UserApi } from '../../api/protected/user/user';
import { CandidateUtil } from '../../utils/candidate-util';
import { CandidateApi } from '../../api/protected/candidates/candidate';
import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';

const ProfilePreferencesSchema = CandidateSchema.pick({
  practiceStartYear: true,
}).extend({
  practicingYears: z.number().nonnegative().nullable(),
});

export const ProfilePreferencesView = () => {
  const [{ data: user }] = useApi(UserApi.readUser());
  const { candidateId: urlCandidateId } = useParams();

  const candidateId = CandidateUtil.reconcileUserCandidateId(
    user,
    urlCandidateId
  );
  const [{ data: candidate }] = useApi(CandidateApi.readCandidate(candidateId));
  const { practiceStartYear } = candidate;

  const thisYear = new Date().getFullYear();
  const practicingYears = thisYear - practiceStartYear;

  const getYearOptions = () => {
    const data = [
      {
        label: '< 1 year',
        value: 0,
      },
      {
        label: '1 year',
        value: 1,
      },
    ];

    for (let i = 2; i <= 75; i += 1) {
      data.push({
        label: `${i} years`,
        value: i,
      });
    }

    return data;
  };

  return (
    <Grid name="PROFILE_PREFERENCES">
      <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
        <PreferencesNavigation />
        <Gutter only="smallScreen" bottom="16px" />
      </GridColumn>
      <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
        <Form
          schema={ProfilePreferencesSchema}
          onSubmit={async formData => {
            await CandidateApi.updateCandidate(
              candidateId,
              CandidateSchema.partial().parse(formData)
            );
            await CandidateApi.refreshCandidate(candidateId);
            return CandidateProfileIssuesApi.refreshCandidateProfileIssues(
              candidateId
            );
          }}
          initialValues={{
            practiceStartYear: candidate.practiceStartYear,
            practicingYears,
          }}
          submitOnChange
        >
          {({ values, setValues }) => (
            <Card>
              <CardHeader>
                <SmallHeader>Profile</SmallHeader>
              </CardHeader>
              <CardSection>
                <Grid>
                  <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                    <Dropdown
                      label="Legal Experience"
                      description="Years Practicing Law"
                      name="practicingYears"
                      options={getYearOptions()}
                      displayKey="label"
                      valueKey="value"
                      onChange={(value: number) => {
                        setValues({
                          ...values,
                          practiceStartYear: thisYear - value,
                        });
                      }}
                    />
                  </GridColumn>
                </Grid>
              </CardSection>
            </Card>
          )}
        </Form>
      </GridColumn>
    </Grid>
  );
};
