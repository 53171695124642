import React from 'react';
import {
  Candidate,
  CandidateLanguage,
  CandidateLanguageFormSchema,
} from '@axiom/validation';
import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Gutter,
  Dropdown,
  Grid,
  GridRow,
  GridColumn,
  useApi,
} from '@axiom/ui';
import { LanguageConst } from '@axiom/const';

import { LanguagesApi } from '../../api/protected/languages/languages';
import { CandidateApi } from '../../api/protected/candidates/candidate';
import { CandidateLanguagesApi } from '../../api/protected/candidates/candidate-languages';
import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';
import { CandidateLanguageUtil } from '../../utils/candidate-language-util';

import { LanguagesBannerPrompt } from './LanguagesBannerPrompt';

const { LANGUAGE_PROFICIENCIES, LANGUAGE_SKILLS, LANGUAGE_CEFR_LEVELS } =
  LanguageConst;

const proficiencyOptions = Object.keys(LANGUAGE_PROFICIENCIES).map(key => ({
  label: LANGUAGE_PROFICIENCIES[key],
  value: key,
}));
const skillOptions = Object.keys(LANGUAGE_SKILLS).map(key => ({
  label: LANGUAGE_SKILLS[key],
  value: key,
}));
const CEFROptions = Object.keys(LANGUAGE_CEFR_LEVELS).map(key => ({
  label: LANGUAGE_CEFR_LEVELS[key as keyof typeof LANGUAGE_CEFR_LEVELS],
  value: key,
}));

export const LanguagesItemEdit = ({
  candidateId,
  candidateLanguages = [],
  actionedLanguages = [],
  onClose = () => {},
}: {
  candidateId: Candidate['id'];
  candidateLanguages?: CandidateLanguage[];
  actionedLanguages?: CandidateLanguage[];
  onClose?: () => void;
}) => {
  const [{ data: languages }] = useApi(LanguagesApi.readLanguages());

  const initialValues =
    CandidateLanguageUtil.formatLanguagesForForm(actionedLanguages);

  return (
    <Form
      name="LANGUAGES_ITEM_EDIT_FORM"
      schema={CandidateLanguageFormSchema}
      initialValues={initialValues}
      onSubmit={async changedValues => {
        /**
         * The PUT for candidate languages expects all languages back on save, even those that didn't change, because it
         * removes everything and re-adds, so we have to filter out what records we pulled in for the form.
         *
         * We can't look at id though because that's actually the languageId, and you could have multiple proficiencies for the same
         * language, so we're looking for records with the three cats above to figure that out.
         *
         * If all three are blank, we can assume this is a new entry and don't need to filter
         */

        // Remove the initial form values from candidate languages
        let submittableLanguages = CandidateLanguageUtil.filterActionedLanguage(
          candidateLanguages,
          initialValues
        );

        const languageSkills =
          changedValues.languageSkills || initialValues.languageSkills;

        // create new languages to add for the body of the put
        const formLanguages = languageSkills.map(skill => ({
          ...initialValues,
          ...changedValues,
          languageSkill: skill,
        }));

        // combines non-modified values with the new languages for the body of the PUT
        submittableLanguages = submittableLanguages.concat(formLanguages);

        // normalize the candidate languages into the expected format for the body of the PUT
        const normalizedCandidateLanguages =
          CandidateLanguageUtil.normalizeCandidateLanguages(
            submittableLanguages
          );

        await CandidateLanguagesApi.updateLanguages(
          candidateId,
          normalizedCandidateLanguages
        );

        await CandidateLanguagesApi.refreshLanguages(candidateId);
        await CandidateApi.refreshCandidate(candidateId);
        await CandidateProfileIssuesApi.refreshCandidateProfileIssues(
          candidateId
        );
        return onClose();
      }}
    >
      {({ fireSubmit }) => {
        return (
          <Modal name="LANGUAGES_EDIT_MODAL">
            <ModalHeader name="LANGUAGES_EDIT_MODAL_HEADER" onClose={onClose}>
              Edit Language
            </ModalHeader>
            <ModalSection disableScrolling>
              {candidateLanguages.length === 0 && <LanguagesBannerPrompt />}
              <Gutter bottom="16px" />
              <Grid name="LANGUAGES_FIELDS">
                <GridRow gutterBottom="16px">
                  <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                    <Dropdown
                      displayKey="name"
                      name="id"
                      options={languages}
                      valueKey="id"
                      label="Language"
                    />
                    <Gutter bottom="16px" only="smallScreen" />
                  </GridColumn>
                  <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                    <Dropdown
                      displayKey="label"
                      name="languageProficiency"
                      options={proficiencyOptions}
                      valueKey="value"
                      label="Proficiency"
                    />
                  </GridColumn>
                </GridRow>
                <GridRow>
                  <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                    <Dropdown
                      displayKey="label"
                      name="languageSkills"
                      valueKey="value"
                      options={skillOptions}
                      label="Skills"
                    />
                    <Gutter bottom="16px" only="smallScreen" />
                  </GridColumn>
                  <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                    <Dropdown
                      displayKey="label"
                      name="languageCEFR"
                      options={CEFROptions}
                      valueKey="value"
                      label="CEFR"
                    />
                  </GridColumn>
                </GridRow>
              </Grid>
            </ModalSection>
            <ModalFooter>
              <Button variation="outline" onClick={onClose} name="CANCEL">
                Cancel
              </Button>
              <Button type="submit" name="SAVE" onClick={fireSubmit}>
                Save
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
