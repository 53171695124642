import React from 'react';
import { CardSection, Gutter, ParagraphHeader } from '@axiom/ui';
import { Candidate, CandidateLanguage } from '@axiom/validation';

import { LanguagesItemDisplay } from './LanguagesItemDisplay';

export const LanguagesGroup = ({
  candidateId,
  candidateLanguages,
  languageName,
  languageGroups,
}: {
  candidateId: Candidate['id'];
  candidateLanguages: CandidateLanguage[];
  languageName: CandidateLanguage['name'];
  languageGroups: Record<string, CandidateLanguage[]>;
}) => {
  return (
    <CardSection divider key={languageName} name="LANGUAGES_GROUP">
      <ParagraphHeader name="LANGUAGE_NAME">{languageName}</ParagraphHeader>
      <Gutter bottom="8px" />
      {Object.entries(languageGroups).map(([key, languageData]) => {
        return (
          <LanguagesItemDisplay
            candidateId={candidateId}
            candidateLanguages={candidateLanguages}
            actionedLanguages={languageData}
            key={key}
          />
        );
      })}
    </CardSection>
  );
};
