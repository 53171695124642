import React from 'react';
import {
  Card,
  CardHeader,
  CardSection,
  ImageCircle,
  Layout,
  ParagraphHeader,
  SmallHeader,
  Visible,
} from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import { CandidateUtil } from '../../utils/candidate-util';

export const ProfileTalentPartner: React.FC<{
  candidate: Candidate;
}> = ({ candidate }) => {
  const user = CandidateUtil.getTalentPartnerUser(candidate);
  const name = `${user.firstName || ''} ${user.lastName || ''}`.trim();

  return (
    <Card>
      <CardHeader>
        <SmallHeader name="PENDO_PROFILE_TALENT_PARTNER_HEADER">
          Talent Partner
        </SmallHeader>
      </CardHeader>
      <CardSection>
        <Layout position="middle" horizontalGutter="16px">
          <Visible only="largeScreen">
            <ImageCircle size="small" imageName={name} />
          </Visible>
          <div style={{ wordBreak: 'break-word' }}>
            <ParagraphHeader>{name}</ParagraphHeader>
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </div>
        </Layout>
      </CardSection>
    </Card>
  );
};
