import React from 'react';
import { z } from 'zod';
import {
  Candidate,
  Certification,
  CertificationSchema,
} from '@axiom/validation';
import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Gutter,
  Input,
  Dropdown,
  Grid,
  GridRow,
  GridColumn,
} from '@axiom/ui';
import { Certification as CertificationConst } from '@axiom/const';
import { CertificationModel } from '@axiom/ui-models';

import { DateUtil } from '../../utils/date-util';
import { CandidateApi } from '../../api/protected/candidates/candidate';
import { CandidateCertificationsApi } from '../../api/protected/candidates/candidate-certifications';
import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';
import { toDropdownOptions } from '../../utils/to-dropdown-options';

import { AccomplishmentsBannerPrompt } from './AccomplishmentsBannerPrompt';

const {
  QUALIFICATION_AWARDS: { QUALIFICATION, YEAR_OF_CALL, STATE_EXAM },
  FORM_TYPES: { QUALIFICATIONS, CERTIFICATIONS_AND_AWARDS },
} = CertificationConst;

const formSchema = CertificationSchema.pick({
  award: true,
  institution: true,
  stateExamScore: true,
  year: true,
}).extend({
  type: z.enum([CERTIFICATIONS_AND_AWARDS, QUALIFICATIONS]).nullable(),
});

const accomplishmentsYears = DateUtil.getYearOptionsByRange(
  new Date().getFullYear(),
  1950
);

const qualificationYears = DateUtil.getYearOptionsByRange(
  new Date().getFullYear() + 10,
  1950
);

const qualificationOptions = toDropdownOptions([
  QUALIFICATION,
  STATE_EXAM,
  YEAR_OF_CALL,
]);

export const AccomplishmentsItemEdit = ({
  candidateId,
  certification,
  onClose = () => {},
  showBanner = false,
}: {
  candidateId: Candidate['id'];
  certification?: Certification;
  onClose?: () => void;
  showBanner?: boolean;
}) => {
  const certModel = new CertificationModel(certification);
  const getInitialType = () => {
    if (certModel.isQualification()) {
      return QUALIFICATIONS;
    } else if (certModel.isAccomplishment()) {
      return CERTIFICATIONS_AND_AWARDS;
    }
    return null;
  };

  return (
    <Form
      name="ACCOMPLISHMENTS_ITEM_EDIT_FORM"
      schema={formSchema}
      initialValues={{
        ...certification,
        stateExamScore: certification.stateExamScore ?? null,
        type: getInitialType(),
      }}
      onSubmit={async formData => {
        const submitData = { ...certification, ...formData };

        if (!submitData.stateExamScore) {
          delete submitData.stateExamScore;
        }

        if (certification.id) {
          await CandidateCertificationsApi.updateCertification(
            certification.id,
            formSchema.partial().parse(submitData)
          );
        } else {
          await CandidateCertificationsApi.createCertification(
            candidateId,
            CertificationSchema.partial().parse(submitData)
          );
        }
        await CandidateApi.refreshCandidate(candidateId);
        await CandidateProfileIssuesApi.refreshCandidateProfileIssues(
          candidateId
        );
        return onClose();
      }}
    >
      {({ fireSubmit, values }) => {
        return (
          <Modal name="ACCOMPLISHMENTS_ITEM_EDIT_MODAL">
            <ModalHeader
              name="ACCOMPLISHMENTS_ITEM_EDIT_MODAL_HEADER"
              onClose={onClose}
            >
              Edit Accomplishments
            </ModalHeader>
            <ModalSection disableScrolling>
              {showBanner && <AccomplishmentsBannerPrompt />}
              <Gutter bottom="16px" />
              <Dropdown
                displayKey="label"
                name="type"
                options={toDropdownOptions([
                  CERTIFICATIONS_AND_AWARDS,
                  QUALIFICATIONS,
                ])}
                valueKey="value"
                label="Accomplishment Type"
                disabled={!!certification.id}
              />
              <Gutter bottom="16px" />
              {values.type === CERTIFICATIONS_AND_AWARDS && (
                <Grid name="CERTIFICATIONS_AND_AWARDS_FIELDS">
                  <GridRow gutterBottom="16px">
                    <GridColumn>
                      <Input name="award" label="Certification or Award Name" />
                    </GridColumn>
                  </GridRow>
                  <GridRow>
                    <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                      <Input name="institution" label="Awarding Organization" />
                      <Gutter bottom="16px" only="smallScreen" />
                    </GridColumn>
                    <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                      <Dropdown
                        displayKey="label"
                        name="year"
                        options={accomplishmentsYears}
                        valueKey="value"
                        label="Year Received"
                      />
                    </GridColumn>
                  </GridRow>
                </Grid>
              )}
              {values.type === QUALIFICATIONS && (
                <Grid name="QUALIFICATION_FIELDS">
                  <GridRow gutterBottom="16px">
                    <GridColumn>
                      <Input name="institution" label="Institution" />
                    </GridColumn>
                  </GridRow>
                  <GridRow>
                    <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                      <Dropdown
                        displayKey="label"
                        name="award"
                        options={qualificationOptions}
                        valueKey="value"
                        label="Qualification Type"
                      />
                      <Gutter bottom="16px" only="smallScreen" />
                    </GridColumn>
                    <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                      <Dropdown
                        displayKey="label"
                        name="year"
                        options={qualificationYears}
                        valueKey="value"
                        label="Year"
                      />
                    </GridColumn>
                  </GridRow>
                  {values.award === STATE_EXAM && (
                    <GridRow gutterTop="16px">
                      <GridColumn>
                        <Input
                          name="stateExamScore"
                          label="Score"
                          description="Saved scores are not visible in your profile or shared in client submissions."
                        />
                      </GridColumn>
                    </GridRow>
                  )}
                </Grid>
              )}
            </ModalSection>
            <ModalFooter>
              <Button variation="outline" onClick={onClose} name="CANCEL">
                Cancel
              </Button>
              <Button type="submit" name="SAVE" onClick={fireSubmit}>
                Save
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
