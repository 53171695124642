import { arrayOf, shape } from 'prop-types';

import CollectionStateHelper from '../../lib/collection-state-helper';
import SubmitStateHelper from '../../lib/submit-state-helper';
import { CandidateLanguageShape } from '../../models/language-model';
import { getLanguagesByCandidateId } from '../../api/protected/candidates/candidate-languages-legacy';

export const CANDIDATE_LANGUAGE_NAMESPACE = 'CANDIDATE_LANGUAGE';

const stateGetLanguages = new CollectionStateHelper(
  CANDIDATE_LANGUAGE_NAMESPACE,
  'FETCH'
);
const stateDeleteLanguage = new SubmitStateHelper(
  CANDIDATE_LANGUAGE_NAMESPACE,
  'DELETE_EXP'
);

export const CandidateLanguagesStore = {
  getReducers() {
    return {
      ...stateGetLanguages.generateReducers(),
      ...stateDeleteLanguage.generateReducers(),
    };
  },
  getStateShape() {
    return shape({
      ...stateGetLanguages.getStructure(this.getDataShape()),
      submits: shape({
        ...stateDeleteLanguage.getStructure(),
      }),
    });
  },
  getDataShape() {
    return arrayOf(CandidateLanguageShape);
  },

  actionFetchLanguages(candidateId) {
    return stateGetLanguages.fetchCollection(
      getLanguagesByCandidateId(candidateId)
    );
  },
  selectLanguagesState(state) {
    return stateGetLanguages.select(state);
  },
  selectLanguages(state) {
    return stateGetLanguages.selectData(state) || [];
  },
};
