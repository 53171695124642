import React from 'react';
import { ProfileCompletionCriteriaDetail } from '@axiom/const';
import { Badge, useApi } from '@axiom/ui';
import { CandidateProfileIssuesUtils } from '@axiom/utils';
import { Candidate } from '@axiom/validation';

import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';
import { CandidateApi } from '../../api/protected/candidates/candidate';
import { CandidateOpportunitiesLegacyApi } from '../../api/protected/candidates/candidate-opportunities-legacy';

export const ProfileIssuesCounter: React.FC<{
  candidateId: Candidate['id'];
}> = ({ candidateId }) => {
  const [
    { data: candidate },
    { data: candidateOpportunities },
    { data: issues },
  ] = useApi(
    CandidateApi.readCandidate(candidateId),
    CandidateOpportunitiesLegacyApi.readOpportunitiesByCandidateId(candidateId),
    CandidateProfileIssuesApi.readCandidateProfileIssues(candidateId)
  );
  const neededCompletionSteps: ProfileCompletionCriteriaDetail[] =
    CandidateProfileIssuesUtils.getNeededCompletionSteps(issues);
  return CandidateProfileIssuesUtils.shouldShowProfileCompletionSection(
    candidate,
    candidateOpportunities,
    issues
  ) ? (
    <Badge background="orange" name="profile-issues-count">
      {neededCompletionSteps.length}
    </Badge>
  ) : null;
};
