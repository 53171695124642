import React, { useMemo, useState } from 'react';
import {
  Candidate,
  CandidateProfileIssue,
  Experience,
  Tag,
  Taxonomy,
} from '@axiom/validation';
import {
  Banner,
  Button,
  Card,
  CardHeader,
  CardSection,
  Layout,
  LayoutItem,
  SmallHeader,
} from '@axiom/ui';

import { CandidateExperiencesUtil } from '../../utils/candidate-experiences-util';

import { ExperienceItemDisplay } from './ExperienceItemDisplay';
import { ExperienceItemNonAxiomEdit } from './ExperienceItemNonAxiomEdit';
import { ExperienceItemAxiomEdit } from './ExperienceItemAxiomEdit';

export const ExperiencesCard = ({
  experiences,
  industries,
  skills,
  candidateId,
  issues,
}: {
  experiences: Experience[];
  industries: Taxonomy['industries'];
  skills: Tag[];
  candidateId: Candidate['id'];
  issues: CandidateProfileIssue[];
}) => {
  const newFormId = 'new';
  const [showEditFormFor, setShowEditFormFor] = useState(null);
  const shouldShowMergeTip: Record<Experience['id'], boolean> = useMemo(
    () => CandidateExperiencesUtil.createShouldShowMergeTipMapping(experiences),
    [experiences]
  );
  return (
    <Card name="EXPERIENCES">
      <CardHeader>
        <Layout position="middle">
          <LayoutItem fluid>
            <SmallHeader>Experience</SmallHeader>
          </LayoutItem>
          {!showEditFormFor && (
            <Button
              name="ADD_EXPERIENCE"
              pattern="secondary"
              onClick={() => {
                setShowEditFormFor(newFormId);
              }}
              icon="plus"
              variation="minimal"
            >
              Add
            </Button>
          )}
        </Layout>
      </CardHeader>
      {showEditFormFor === newFormId && (
        <CardSection divider name="NEW_EXPERIENCE">
          <ExperienceItemNonAxiomEdit
            candidateId={candidateId}
            industries={industries}
            onClose={() => {
              setShowEditFormFor(null);
            }}
            skills={skills}
            issues={issues}
          />
        </CardSection>
      )}
      {experiences.map(experience => (
        <CardSection divider key={experience.id} name="EXPERIENCE_ITEM">
          <ExperienceItemDisplay
            candidateId={candidateId}
            experience={experience}
            industries={industries}
            openForm={() => setShowEditFormFor(experience.id)}
            showMergeTip={shouldShowMergeTip[experience.id] ?? false}
            issues={issues}
          />
          {showEditFormFor === experience.id && (
            <>
              {experience.isAxiom ? (
                <ExperienceItemAxiomEdit
                  candidateId={candidateId}
                  experience={experience}
                  industries={industries}
                  onClose={() => setShowEditFormFor(null)}
                  skills={skills}
                  issues={issues}
                />
              ) : (
                <ExperienceItemNonAxiomEdit
                  candidateId={candidateId}
                  experience={experience}
                  industries={industries}
                  onClose={() => setShowEditFormFor(null)}
                  skills={skills}
                  issues={issues}
                />
              )}
            </>
          )}
        </CardSection>
      ))}
      {experiences.length === 0 && !showEditFormFor && (
        <CardSection name="NO_EXPERIENCES">
          <Banner impact="low" type="info">
            No experience added
          </Banner>
        </CardSection>
      )}
    </Card>
  );
};
