import React from 'react';
import { z } from 'zod';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {
  Button,
  Dropdown,
  FluidButtonLayout,
  Form,
  Grid,
  GridColumn,
  GridRow,
  Input,
  Checkbox,
  Textarea,
  DropdownTree,
  Gutter,
  DateUtil,
  FormGroup,
  DateInput,
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Visible,
  TaxonomyUtil,
  Banner,
} from '@axiom/ui';
import {
  Candidate,
  CandidateProfileIssue,
  Experience,
  ExperienceEditSchema,
  Tag,
  Taxonomy,
} from '@axiom/validation';
import { SchemaLocation, SchemaMonthYear } from '@axiom/types';
import { Location } from '@axiom/ui/src/components/form/Location/Location';
import { CandidateProfileIssuesUtils } from '@axiom/utils';

import {
  CompanyTypeahead,
  getLabel,
} from '../CompanyTypeahead/CompanyTypeahead';
import { restructureForDropdownTree } from '../../utils/skills-util';
import { CandidateExperienceApi } from '../../api/protected/candidates/candidate-experience';
import { ExperienceApi } from '../../api/protected/experiences/experience';
import { CandidateApi } from '../../api/protected/candidates/candidate';
import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';

import { ExperienceItemEditTips } from './ExperienceItemEditTips';
import { ExperienceMissingIndustryPrompt } from './ExperienceMissingIndustryPrompt';

const formSchema = ExperienceEditSchema.omit({
  industryIsOverridden: true,
}).extend({
  location: SchemaLocation,
  currentlyWorking: z.boolean().nullish(),
  startDate: SchemaMonthYear,
  endDate: SchemaMonthYear.nullish(),
  skills: z.array(z.string()).nullish(),
  startMonthYearGroup: z.string().optional(),
  company: z.string(),
});

export const ExperienceItemNonAxiomEdit = ({
  candidateId,
  experience = {},
  industries,
  onClose,
  skills,
  issues,
}: {
  candidateId: Candidate['id'];
  experience?: Experience;
  industries: Taxonomy['industries'];
  onClose: () => void;
  skills: Tag[];
  issues: CandidateProfileIssue[];
}) => {
  const skillsOptions = restructureForDropdownTree(skills);
  const industryOptions = TaxonomyUtil.getIndustryOptions(industries);

  return (
    <Form
      name="EXPERIENCE_ITEM_NON_AXIOM_EDIT_FORM"
      schema={formSchema}
      initialValues={{
        ...experience,
        candidateId: experience.candidateId ?? candidateId,
        currentlyWorking: !experience.endDate,
        startDate: experience.startDate,
        endDate: experience.endDate,
        location:
          experience.locationAddressComponents ||
          experience.locationName ||
          experience.locationPlaceId
            ? {
                locationAddressComponents: experience.locationAddressComponents,
                locationName: experience.locationName,
                locationPlaceId: experience.locationPlaceId,
              }
            : null,
        client: experience.client,
        clientDomain: experience.clientDomain,
        company: getLabel(experience.client, experience.clientDomain),
        tags: experience.tags?.map(t => t.id),
      }}
      onSubmit={async (formData, actions) => {
        // If no changes, just close form
        if (isEmpty(formData)) {
          return onClose();
        }

        const startDate = formData.startDate ?? experience.startDate;
        const endDate = formData.endDate ?? experience.endDate;

        if (moment(startDate).isAfter(endDate)) {
          actions.setFieldError(
            'startMonthYearGroup',
            'This must be earlier than or equal to end date'
          );
          return false;
        }

        const submitData = {
          candidateId,
          ...formData,
          // handles edge case when user changes clientDomain without modification to client
          // ex. Axiom as a new option without domain, then later changed to Axiom from the clearbit response
          ...(formData.clientDomain !== undefined && !formData.client
            ? { client: experience.client }
            : {}),
          ...(formData.location ? formData.location : {}),
          ...(formData.tags === undefined ? {} : { tags: formData.tags ?? [] }),
        };

        if (experience.id) {
          // update experience
          await ExperienceApi.updateExperience(
            experience.id,
            ExperienceEditSchema.partial().parse(submitData)
          );
        } else {
          // create experience
          await ExperienceApi.createExperience(
            candidateId,
            ExperienceEditSchema.partial().parse(submitData)
          );
        }

        await CandidateExperienceApi.refreshExperiences(candidateId);
        await CandidateApi.refreshCandidate(candidateId);
        await CandidateProfileIssuesApi.refreshCandidateProfileIssues(
          candidateId
        );

        return onClose();
      }}
    >
      {({ fireSubmit, setValues, values }) => {
        return (
          <Modal size="large">
            <ModalHeader
              name="non-axiom-edit-modal-header"
              onClose={() => onClose()}
            >
              Edit experience
            </ModalHeader>
            <ModalSection>
              <Grid>
                <GridRow>
                  <GridColumn smallScreenWidth={12} largeScreenWidth={5}>
                    {CandidateProfileIssuesUtils.hasExperienceWithMissingIndustryIssue(
                      issues,
                      experience.id
                    ) && (
                      <Gutter bottom="16px">
                        <Banner
                          name="ADD_INDUSTRY_HINT_EDIT"
                          type="info"
                          impact="high"
                        >
                          <ExperienceMissingIndustryPrompt />
                        </Banner>
                      </Gutter>
                    )}
                    <ExperienceItemEditTips />
                    <Visible only="smallScreen">
                      <Gutter bottom="24px" />
                    </Visible>
                  </GridColumn>
                  <GridColumn smallScreenWidth={12} largeScreenWidth={7}>
                    <div>
                      <CompanyTypeahead
                        name="company"
                        label="Company Name"
                        initialValue={{
                          name: values.client,
                          domain: values.clientDomain,
                        }}
                        onChange={payload => {
                          setValues({
                            ...values,
                            client: payload.name,
                            clientDomain: payload.domain,
                          });
                        }}
                      />
                      <Gutter bottom="8px" />
                      <Input name="externalOpportunityName" label="Title" />
                      <Gutter bottom="8px" />
                      <Location
                        name="location"
                        label="Location"
                        placeholder={experience.locationName}
                      />
                      <Gutter bottom="8px" />
                      <Grid>
                        <GridRow>
                          <GridColumn
                            largeScreenWidth={6}
                            tabletWidth={12}
                            mobileWidth={12}
                          >
                            <Grid columns={2}>
                              <GridRow>
                                <GridColumn width={12}>
                                  <DateInput
                                    name="startDate"
                                    label="Start Date"
                                  />
                                </GridColumn>
                                <GridColumn width={12}>
                                  {/* for displaying custom error */}
                                  <FormGroup name="startMonthYearGroup" />
                                </GridColumn>
                              </GridRow>
                            </Grid>
                          </GridColumn>
                          <GridColumn
                            largeScreenWidth={6}
                            tabletWidth={12}
                            mobileWidth={12}
                          >
                            <Grid columns={2}>
                              <GridRow>
                                <GridColumn width={12}>
                                  <DateInput
                                    name="endDate"
                                    label="End Date"
                                    disabled={values.currentlyWorking}
                                  />
                                </GridColumn>
                                <GridColumn width={12}>
                                  <Gutter bottom="8px" />
                                  <Checkbox
                                    name="currentlyWorking"
                                    displayValue="I currently work here"
                                    onChange={v => {
                                      if (v) {
                                        setValues({ ...values, endDate: null });
                                      } else {
                                        setValues({
                                          ...values,
                                          endDate: DateUtil.formatAsDate(
                                            moment().date(1)
                                          ),
                                        });
                                      }
                                    }}
                                  />
                                </GridColumn>
                              </GridRow>
                            </Grid>
                          </GridColumn>
                        </GridRow>
                      </Grid>
                      <Textarea
                        name="description"
                        label="Description of work"
                        full
                        enableSuggestionsFor="portal-ExperienceItemNonAxiomEdit-description"
                      />
                      <Gutter bottom="8px" />
                      <DropdownTree
                        displayKey="name"
                        name="tags"
                        nestedKey="items"
                        options={skillsOptions}
                        valueKey="id"
                        label="Associated skills"
                        description={`
                          Provide the skills that you gained or exercised while in this role.
                          Please be factual and comprehensive as this is the information we
                          will highlight with our clients.
                        `}
                      />
                      <Gutter bottom="8px" />
                      <Dropdown
                        displayKey="label"
                        name="industryValue"
                        options={industryOptions}
                        valueKey="value"
                        label="Industry"
                      />
                      <Gutter bottom="16px" />
                    </div>
                  </GridColumn>
                </GridRow>
              </Grid>
            </ModalSection>
            <ModalFooter>
              <FluidButtonLayout>
                <Button variation="outline" onClick={onClose} name="CANCEL">
                  Cancel
                </Button>
                <Button type="submit" name="SAVE" onClick={fireSubmit}>
                  Save
                </Button>
              </FluidButtonLayout>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
