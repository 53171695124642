import React from 'react';
import { useParams } from 'react-router-dom';
import {
  useApi,
  Grid,
  GridColumn,
  Card,
  CardSection,
  CardHeader,
  SmallHeader,
  Form,
  FormGroup,
  Gutter,
  Radio,
  Flashy,
  Layout,
  Toggle,
  Suspense,
  Button,
  FormLabel,
  CondensedLarge,
} from '@axiom/ui';
import { CandidateSchema } from '@axiom/validation';
import { CandidatesConst } from '@axiom/const';

import { PreferencesNavigation } from '../PreferencesNavigation/PreferencesNavigation';
import { WorkFeedAlerts } from '../WorkFeedAlerts/WorkFeedAlerts';
import { CandidateApi } from '../../api/protected/candidates/candidate';
import { UserApi } from '../../api/protected/user/user';
import { CandidateUtil } from '../../utils/candidate-util';
import { EnvUtil } from '../../utils/env-util';

const { WorkingEmploymentStatuses } = CandidatesConst;

const CommunicationPreferencesSchema = CandidateSchema.pick({
  preferredContactMethod: true,
});

const CommunicationPreferencesAlertsSchema = CandidateSchema.pick({
  isOptInWorkFeedEmail: true,
});

export const CommunicationPreferencesView = () => {
  const [{ data: user }] = useApi(UserApi.readUser());
  const { candidateId: urlCandidateId } = useParams();

  const candidateId = CandidateUtil.reconcileUserCandidateId(
    user,
    urlCandidateId
  );

  const [{ data: candidate }] = useApi(CandidateApi.readCandidate(candidateId));

  const {
    employmentStatus,
    homePhone,
    isAccessibleToWorkFeed,
    isOptInWorkFeedEmail,
    mobilePhone,
    personalEmail,
    preferredContactMethod,
    workEmail,
  } = candidate;

  const showEditInMyApps = WorkingEmploymentStatuses.includes(employmentStatus);
  const talentPartnerUser = CandidateUtil.getTalentPartnerUser(candidate);
  const updatePhoneSubject = 'Please update my contact information';
  const updatePhoneBody = `Dear ${talentPartnerUser.firstName},%0D%0A%0D%0APlease update my contact information in Greenhouse to the following:`;

  return (
    <Grid name="COMMUNICATION_PREFERENCES">
      <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
        <PreferencesNavigation />
        <Gutter only="smallScreen" bottom="16px" />
      </GridColumn>
      <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
        <Layout direction="vertical" verticalGutter="16px">
          <Card>
            <CardHeader>
              <SmallHeader>Communications</SmallHeader>
            </CardHeader>
            <CardSection divider>
              <Form
                name="COMMS-FORM"
                schema={CommunicationPreferencesSchema}
                onSubmit={formData => {
                  return CandidateApi.updateCandidate(
                    candidateId,
                    CandidateSchema.partial().parse(formData)
                  ).then(() => {
                    return CandidateApi.refreshCandidate(candidateId);
                  });
                }}
                initialValues={{
                  preferredContactMethod,
                }}
                submitOnChange
              >
                {() => (
                  <FormGroup
                    name="preferredContactMethod"
                    label="Preferred Email"
                    description="Where would you like us to send notifications about new opportunities or things that need your attention?"
                  >
                    <Layout direction="vertical" verticalGutter="8px">
                      {workEmail && (
                        <Radio
                          name="preferredContactMethod"
                          option="Axiom"
                          displayValue={workEmail}
                        />
                      )}
                      {personalEmail && (
                        <Radio
                          name="preferredContactMethod"
                          option="Personal"
                          displayValue={personalEmail}
                        />
                      )}
                      {workEmail && personalEmail && (
                        <Radio
                          name="preferredContactMethod"
                          option="Both"
                          displayValue="Both"
                        />
                      )}
                      {!workEmail && !personalEmail && (
                        <Flashy name="NO_EMAIL_WARNING" bold color="textAlert">
                          Please reach out to your Axiom contact to set your
                          email.
                        </Flashy>
                      )}
                    </Layout>
                  </FormGroup>
                )}
              </Form>
            </CardSection>
            <CardSection divider>
              <FormLabel>Phone Number</FormLabel>
              <Gutter bottom="8px" />
              <CondensedLarge name="mobilePhone">
                Mobile: {mobilePhone ?? '--'}
              </CondensedLarge>
              <Gutter bottom="8px" />
              <CondensedLarge name="homePhone">
                Telephone: {homePhone ?? '--'}
              </CondensedLarge>
            </CardSection>
            <CardSection divider>
              {showEditInMyApps ? (
                <>
                  <CondensedLarge>
                    Update your contact information in Workday
                  </CondensedLarge>
                  <Gutter bottom="8px" />
                  <Button
                    name="MYAPPS_BUTTON"
                    pattern="secondary"
                    variation="outline"
                    toTab={EnvUtil.myAppsUrl}
                  >
                    Launch My Apps
                  </Button>
                </>
              ) : (
                <CondensedLarge>
                  To update your contact information, reach out to your{` `}
                  <a
                    data-test="REACHOUT_LINK"
                    href={`mailto:${talentPartnerUser.email}?subject=${updatePhoneSubject}&body=${updatePhoneBody}`}
                  >
                    talent partner.
                  </a>
                </CondensedLarge>
              )}
            </CardSection>
          </Card>
          {isAccessibleToWorkFeed && (
            <Card name="ALERTS_PREFERENCES_CARD">
              <CardHeader>
                <SmallHeader>Alerts</SmallHeader>
              </CardHeader>
              <CardSection divider>
                <Form
                  name="ALERTS-FORM"
                  schema={CommunicationPreferencesAlertsSchema}
                  onSubmit={formData => {
                    return CandidateApi.updateCandidate(
                      candidateId,
                      CandidateSchema.partial().parse(formData)
                    ).then(() => {
                      return CandidateApi.refreshCandidate(candidateId);
                    });
                  }}
                  initialValues={{
                    isOptInWorkFeedEmail: !!isOptInWorkFeedEmail,
                  }}
                  submitOnChange
                >
                  {() => (
                    <Toggle
                      name="isOptInWorkFeedEmail"
                      label="Alert Subscriptions"
                      description="I'd like to receive opportunity alerts via email."
                    />
                  )}
                </Form>
              </CardSection>
              <CardSection>
                <Suspense>
                  <WorkFeedAlerts candidateId={candidateId} />
                </Suspense>
              </CardSection>
            </Card>
          )}
        </Layout>
      </GridColumn>
    </Grid>
  );
};
