import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  Banner,
  CondensedLarge,
  Grid,
  GridRow,
  GridColumn,
  Form,
  FormLabel,
  DateUtil,
  Textarea,
  FluidButtonLayout,
  Button,
  Gutter,
  DropdownTree,
  Modal,
  ModalSection,
  ModalHeader,
  ModalFooter,
  Visible,
  Dropdown,
  TaxonomyUtil,
} from '@axiom/ui';
import {
  Candidate,
  CandidateProfileIssue,
  Experience,
  ExperienceEditSchema,
  Tag,
  Taxonomy,
} from '@axiom/validation';
import { CandidateProfileIssuesUtils } from '@axiom/utils';

import { restructureForDropdownTree } from '../../utils/skills-util';
import { ExperienceApi } from '../../api/protected/experiences/experience';
import { CandidateExperienceApi } from '../../api/protected/candidates/candidate-experience';
import { CandidateApi } from '../../api/protected/candidates/candidate';
import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';

import { ExperienceItemEditTips } from './ExperienceItemEditTips';
import { ExperienceMissingIndustryPrompt } from './ExperienceMissingIndustryPrompt';

const formSchema = ExperienceEditSchema.pick({
  description: true,
  tags: true,
  industryValue: true,
  industryIsOverridden: true,
});

export const ExperienceItemAxiomEdit = ({
  candidateId,
  experience,
  industries,
  onClose,
  skills,
  issues,
}: {
  candidateId: Candidate['id'];
  experience: Experience;
  industries: Taxonomy['industries'];
  onClose: () => void;
  skills: Tag[];
  issues: CandidateProfileIssue[];
}) => {
  const skillsOptions = restructureForDropdownTree(skills);
  const industryOptions = TaxonomyUtil.getIndustryOptions(industries);

  return (
    <Form
      name="EXPERIENCE_ITEM_AXIOM_EDIT_FORM"
      schema={formSchema}
      initialValues={{
        ...experience,
        tags: experience.tags?.map(t => t.id),
      }}
      onSubmit={async formData => {
        // If no changes, just close form
        if (isEmpty(formData)) {
          return onClose();
        }

        const submitData = {
          candidateId,
          ...formData,
          ...(formData.tags === undefined ? {} : { tags: formData.tags ?? [] }),
          ...(formData.industryValue && { industryIsOverridden: true }),
        };

        await ExperienceApi.updateExperience(
          experience.id,
          ExperienceEditSchema.partial().parse(submitData)
        );

        await CandidateExperienceApi.refreshExperiences(candidateId);
        await CandidateApi.refreshCandidate(candidateId);
        await CandidateProfileIssuesApi.refreshCandidateProfileIssues(
          candidateId
        );

        return onClose();
      }}
    >
      {({ fireSubmit }) => {
        return (
          <Modal size="large">
            <ModalHeader
              name="axiom-edit-modal-header"
              onClose={() => onClose()}
            >
              Edit experience
            </ModalHeader>
            <ModalSection>
              <Grid>
                <GridRow>
                  <GridColumn smallScreenWidth={12} largeScreenWidth={5}>
                    {CandidateProfileIssuesUtils.hasExperienceWithMissingIndustryIssue(
                      issues,
                      experience.id
                    ) && (
                      <Gutter bottom="16px">
                        <Banner
                          name="ADD_INDUSTRY_HINT_EDIT"
                          type="info"
                          impact="high"
                        >
                          <ExperienceMissingIndustryPrompt />
                        </Banner>
                      </Gutter>
                    )}
                    <ExperienceItemEditTips experience={experience} />
                    <Visible only="smallScreen">
                      <Gutter bottom="24px" />
                    </Visible>
                  </GridColumn>
                  <GridColumn smallScreenWidth={12} largeScreenWidth={7}>
                    <Grid>
                      <GridRow>
                        <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                          <FormLabel>Company Name</FormLabel>
                          <CondensedLarge name="client">
                            {experience.client}
                          </CondensedLarge>
                          <Gutter bottom="8px" />
                        </GridColumn>
                        <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                          <FormLabel>Duration</FormLabel>
                          <CondensedLarge name="duration">
                            {DateUtil.displayDateRange(
                              experience.startDate,
                              experience.endDate
                            )}
                          </CondensedLarge>
                          <Gutter bottom="8px" />
                        </GridColumn>
                        <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                          <FormLabel>Location</FormLabel>
                          <CondensedLarge name="location">
                            {experience.opportunity.locationCityStateCountry}
                          </CondensedLarge>
                          <Gutter bottom="8px" />
                        </GridColumn>
                      </GridRow>
                    </Grid>
                    <Textarea
                      name="description"
                      label="Description of work"
                      full
                      enableSuggestionsFor="portal-ExperienceItemAxiomEdit-description"
                    />
                    <Gutter bottom="8px" />
                    <DropdownTree
                      displayKey="name"
                      name="tags"
                      nestedKey="items"
                      options={skillsOptions}
                      valueKey="id"
                      label="Associated skills"
                      description={`
                          Provide the skills that you gained or exercised while in this role.
                          Please be factual and comprehensive as this is the information we
                          will highlight with our clients.
                        `}
                    />
                    <Gutter bottom="8px" />
                    <Dropdown
                      displayKey="label"
                      name="industryValue"
                      options={industryOptions}
                      valueKey="value"
                      label="Industry"
                    />
                    <Gutter bottom="16px" />
                  </GridColumn>
                </GridRow>
              </Grid>
            </ModalSection>
            <ModalFooter>
              <FluidButtonLayout>
                <Button variation="outline" onClick={onClose} name="CANCEL">
                  Cancel
                </Button>
                <Button type="submit" name="SAVE" onClick={fireSubmit}>
                  Save
                </Button>
              </FluidButtonLayout>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
